import { Controller } from "@hotwired/stimulus"
// https://trvswgnr.github.io/bs5-lightbox/#single-image
//const Lightbox = require("bs5-lightbox");
import Lightbox from 'bs5-lightbox';

export default class extends Controller {

  connect() {
    const element = this.element;
    this.element.addEventListener("click", (event) => {
      event.preventDefault();
      const lightbox = new Lightbox(element, {
	// size: 'fullscreen'
	// options
      });
      lightbox.show();
      if (element.dataset.lightboxClass)
        lightbox.modalElement.classList.add(element.dataset.lightboxClass);
    })
  }

}
