/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import "@fontsource/jost/300.css";
import "@fontsource/jost/400.css";
import "@fontsource/jost/500.css";
import "@fontsource/jost/600.css";
import "@fontsource/jost/700.css";
import "@fontsource/jost/800.css";

/* our styles */
import "stylesheets/public.scss";

/* turbo and stimulus */
import "@hotwired/turbo-rails"
Turbo.setProgressBarDelay(100);

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();
const context = require.context("../src/controllers/public", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

/* js stuff */
import * as bootstrap from "bootstrap";
// FIXME: do we need this?
global.bootstrap = bootstrap;

global.accordion        = require("../src/public/accordion.js");
global.external_links   = require("../src/public/external-links.js");
global.buy              = require("../src/public/buy");
global.stripe           = require("../src/public/stripe.js");
global.newsletter_promo = require("../src/public/newsletter-promo.js");

import cookieConsentInit from "../src/public/cookie-consent-config";

document.addEventListener('turbo:load', startApp)

function startApp(event) {
  // const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
  // const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl))

  buy.init();
  newsletter_promo.init();
  external_links.init();
  accordion.init();
  window.cookieConsentForm = cookieConsentInit();
}
