import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const form = this.element;
    this.element.addEventListener("submit", (event) => {
      form.querySelector("input[type=submit]").disabled = "disabled";
      document.body.classList.add("busy-indicator")
    });
  }

}
