function init() {
  // FIXME: change to turbo stream
  // // buy_label clicks
  document.querySelectorAll('form.buy_button').forEach((element) => {
    element.addEventListener("turbo:submit-end", (event) => {
      // FIXME: check error!
      animate(event.target);
    });
  });

  // basket amount change
  document.querySelectorAll("body.page-checkout-basket .js-count").forEach((element) => {
    element.addEventListener("change", (event) => {
      event.stopPropagation();
      event.preventDefault();
      document.getElementById("basket-form").submit();
    });
  });
}

function animate(target) {
  const cart = document.getElementById("basket-link");
  const cart_offset = _offset(cart);

  // FIXME: fix selector
  let img = target.closest("article").querySelector(".cover_wrapper");
  // FIXME: checkVisibility is not available on older ios devices: https://caniuse.com/?search=checkVisibility
  if (!img.checkVisibility())
    img = target.closest("article").querySelector(".detail-md .cover_wrapper");

  const offset = _offset(img);
  const flyimg = img.cloneNode(true);
  flyimg.style.setProperty("opacity", 0.5);
  flyimg.style.setProperty("position", "absolute");
  flyimg.style.setProperty("z-index", 1000);
  flyimg.style.setProperty("width", img.offsetWidth + "px");
  flyimg.style.setProperty("height", img.offsetHeight + "px");
  flyimg.style.setProperty("top", (offset.top - 3) + "px");
  flyimg.style.setProperty("left", offset.left + "px");
  flyimg.style.setProperty("transform", "scale(1)");
  flyimg.style.setProperty("transition", "all 0.5s ease-in-out");
  document.body.appendChild(flyimg);
  setTimeout(function() {
    flyimg.style.setProperty("width", "30px");
    flyimg.style.setProperty("height", "30px");
    flyimg.style.setProperty("top", cart_offset.top + "px");
    flyimg.style.setProperty("left", (cart_offset.left + 20) + "px");
  }, 100);
  setTimeout(function() {
    flyimg.style.setProperty("transition", "all 0.25s ease-in-out");
    flyimg.style.setProperty("width", "0px");
    flyimg.style.setProperty("height", "0px");
  }, 100 + 500);
  setTimeout(function() {
    cart.style.setProperty("transition", "background-color 0.1s ease-in");
    cart.style.setProperty("background-color", "#ffcc00");
    document.body.removeChild(flyimg);
  }, 100 + 500 + 250);
  setTimeout(function() {
    cart.style.setProperty("transition", "background-color 0.1s ease-out");
    cart.style.removeProperty("background-color");
  }, 100 + 500 + 250 + 200);
}

function _offset(el) {
  let rect = el.getBoundingClientRect();
  return {
    top:  rect.top  + window.scrollY,
    left: rect.left + window.scrollX
  }
}

// // run this only once
// $(function() {
//   // ajax basket success
//   $(document).on('ajax:success', 'form.buy_button', function(evt, data, status, xhr) {
//     var cart = $('#basket-link');
//     var img = $(this).closest('.article').find('.cover_wrapper');
//     if (img.length == 0) {
//       img = $('div.js-cover:visible').find('.cover_wrapper');
//     }
//     var flyimg = img.clone()
// 	.offset({top: img.offset().top, left: img.offset().left})
// 	.css({ opacity: '0.5', position: 'absolute', 'z-index': 1000, width: img.width(), height: img.height() })
// 	.appendTo($('body'))
// 	.animate({top: cart.offset().top + 5, left: cart.offset().left + 10, width: 30, height: 30}, 1000, 'easeInOutExpo')
//     setTimeout(function() {
//       cart.effect("highlight", "swing", 300);
//       cart.find('a').html(xhr.responseText);
//     }, 1500);
//     flyimg.animate({
//       width: 0, height: 0
//     }, function() {
//       $(this).detach()
//     });
//   });

//   // ajax basket error
//   $(document).on('ajax:error', 'form.buy_button', function(evt, xhr, status, error){
//     alert("Sorry! " + error + " // " + xhr.responseText);
//   });
// });

module.exports = { init }
